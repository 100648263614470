<template>
	<div class="my-account">
		<div class="content-title">账户信息</div>
		<el-row>
			<el-col :span="6" class="item-title">账户类型</el-col>
			<el-col :span="6" class="item-value">{{
				settleInfoDo.accountType | accountTypeFM
			}}</el-col>
			<el-col :span="3" class="item-title">开户名</el-col>
			<el-col :span="6" class="item-value">{{
				settleInfoDo.accountName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算账号</el-col>
			<el-col :span="6" class="item-value">{{
				settleInfoDo.accountNo
			}}</el-col>
			<el-col :span="3" class="item-title">开户行</el-col>
			<el-col :span="6" class="item-value">{{
				settleInfoDo.bankName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">开户地区</el-col>
			<el-col :span="6" class="item-value"
				>{{ settleInfoDo.accountProvinceZh
				}}{{ settleInfoDo.accountCityZh }}</el-col
			>
			<el-col :span="3" class="item-title">开户支行</el-col>
			<el-col :span="6" class="item-value">{{
				settleInfoDo.branchName
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title">结算人身份证号</el-col>
			<el-col :span="12" class="item-value">{{
				maskIDNumber(settleInfoDo.accountIdCard)
			}}</el-col>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title"> </el-col>
			<el-col :span="12" class="item-value"
				><el-button type="primary" @click="handleUpdate"
					>修改</el-button
				></el-col
			>
		</el-row>
		<el-row>
			<el-col :span="6" class="item-title"> </el-col>
			<el-col :span="12" class="item-value"></el-col>
		</el-row>
		<div class="content-title">成本信息</div>
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane
				:key="item.id"
				v-for="item in agentPolicyCost"
				:label="item.policyName"
				:name="item.name"
			>
				<div style="padding-bottom: 10px;">
					<span>税点：</span>{{ item.taxPoint }}%
				</div>
				<table class="rate-table" style="width:950px">
					<tr>
						<th>业务类型</th>
						<th>卡类型</th>
						<th>T1成本（比例：%，封顶：元）</th>
						<th>T0成本（%）</th>
						<th>T0提现费成本（元）</th>
					</tr>
					<tr v-for="list in item.costList" :key="list.id">
						<td>{{ list.payTypeName }}</td>
						<td>{{ list.cardType | formateCardType }}</td>
						<td>
							<div>
								<span
									v-if="
										list.payTypeCode == 'POS' &&
											list.cardType == '2'
									"
									>比例：</span
								>{{ list.t1Rate }}
							</div>
							<div
								v-if="
									list.payTypeCode == 'POS' &&
										list.cardType == '2'
								"
							>
								封顶{{ list.t1TopFee }}
							</div>
						</td>
						<td>{{ list.t0Rate }}</td>
						<td>
							<div
								v-if="
									list.payTypeCode == 'POS' &&
										list.cardType == '1'
								"
							>
								{{ list.cashFee }}
							</div>
							<span v-else>-</span>
						</td>
					</tr>
				</table>
			</el-tab-pane>
		</el-tabs>
		<CustomForm
			:on-fresh="showCustomForm"
			:selectRow="selectRow"
			@on-close="handlerCustomFormOnclose"
		></CustomForm>
	</div>
</template>
<script>
import { ProfitManage } from "@/api";
import CustomForm from "./CustomForm";
export default {
	name: "myAccount",
	data() {
		return {
			settleInfoDo: {},
			agentPolicyCost: {},
			activeName: "",
			selectRow: {},
			showCustomForm: false,
			taxPoint: ""
		};
	},
	components: { CustomForm },
	created() {
		ProfitManage.myinfo.queryMyInfo().then(res => {
			this.agentPolicyCost = res.data.agentPolicyCostInfo;
			this.taxPoint = res.data.agentInfoDo.taxPoint * 100;
		});
		ProfitManage.myinfo.listMyAccount().then(res => {
			this.settleInfoDo = res.data.settleInfoDo;
		});
	},
	methods: {
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
		},
		handleUpdate() {
			this.selectRow = this.settleInfoDo;
			this.showCustomForm = true;
		}
	}
};
</script>
